import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  GetAppointmentForChangeDeliveryMethodDocument,
  useChangeDeliveryMethodMutation,
} from '~/graphql';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';

export const useChangeDeliveryMethod = () => {
  const resetState = useResetRecoilState(changeDeliveryMethodState);
  const { appointmentId } = useRecoilValue(changeDeliveryMethodState);
  const [changeDeliveryMethod, { error, loading }] = useChangeDeliveryMethodMutation({
    refetchQueries: [
      { query: GetAppointmentForChangeDeliveryMethodDocument, variables: { id: appointmentId } },
    ],
    onCompleted: () => {
      resetState();
    },
  });

  return {
    changeDeliveryMethod,
    loading,
    error,
  };
};
