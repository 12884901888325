import { useTheme } from '@emotion/react';
import React from 'react';

import { Alert, Loader, Text } from '~/components/blocks';
import { AppointmentDeliveryMethod } from '~/graphql';

import { TextSpan } from '../../../blocks/TextSpan';

type Props = {
  appointmentId: string;
  availableSameDayDelivery: boolean;
  errorMessage?: string;
  deliveryMethod: AppointmentDeliveryMethod;
  isPending: boolean;
  loading: boolean;
  enableUberOrgSetting: boolean;
};

export const SameDayDeliveryAlert = React.memo((props: Props) => {
  const {
    availableSameDayDelivery,
    deliveryMethod,
    errorMessage,
    loading,
    isPending,
    enableUberOrgSetting,
  } = props;
  const theme = useTheme();

  const isSameDayDelivery = deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery;

  if (loading) {
    return <Loader open inside />;
  }

  if (errorMessage) {
    return (
      <Alert status="error" marginY={theme.space.m}>
        {errorMessage}
      </Alert>
    );
  }

  if (!enableUberOrgSetting) {
    return (
      <Alert status={isSameDayDelivery ? 'error' : 'warning'} marginY={theme.space.m}>
        <Text>
          設定が無効になっているため、当日配達
          <TextSpan size="s" text="（Uber&nbsp;Eats）" />
          を利用できません
          {isSameDayDelivery && '。患者にご相談の上、受取方法を変更してください'}
        </Text>
      </Alert>
    );
  }

  if (enableUberOrgSetting && !availableSameDayDelivery) {
    return (
      <Alert status={isSameDayDelivery ? 'error' : 'warning'} marginY={theme.space.m}>
        <Text>
          薬局から患者指定住所まで距離が離れているため、当日配達
          <TextSpan size="s" text="（Uber&nbsp;Eats）" />
          を利用できません
          {isSameDayDelivery && '。患者にご相談の上、受取方法を変更してください'}
        </Text>
      </Alert>
    );
  }

  if (isPending) {
    return (
      <Alert status="warning" marginY={theme.space.m}>
        <Text>
          「当日配達
          <TextSpan size="s" text="（Uber&nbsp;Eats）" />」
          から別の受け渡し方法に変更すると、当日配達および集荷依頼はキャンセルされます
        </Text>
      </Alert>
    );
  }
});

SameDayDeliveryAlert.displayName = 'SameDayDeliveryAlert';
