import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import {
  Box,
  Button,
  EntryList,
  Flex,
  Icon,
  Loader,
  Modal,
  Radio,
  RadioGroup,
  Text,
} from '~/components/blocks';
import { TextSpan } from '~/components/blocks/TextSpan';
import { AppointmentDeliveryMethod, UberDeliveryStatus } from '~/graphql';
import { useFeatureFlag } from '~/hooks/use-feature-flag';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { requestUberDeliveryModalState } from '~/state/reception/atoms';

import { useUberOrganizationSetting } from '../../../hooks/use-uber_organization_setting';
import { UberFreeChargePromotionText } from '../UberFreeChargePromotionText';
import { PatientNameWithAddress } from './PatientNameWithAddress';
import { SameDayDeliveryAlert } from './SameDayDeliveryAlert';
import { Fields } from './types';
import { useChangeDeliveryMethod } from './use-change_delivery_method';
import { useFetchAppointment } from './use-fetch_appointment';
import { validationSchema } from './validation';

const Main = styled(Box)(({ theme }) =>
  css({
    padding: theme.space.m,
    minHeight: '400px',
  }),
);

const CustomRadio = styled(Radio)(() =>
  css({
    fontWeight: 'bold',
  }),
);

const RadioSubText = styled(Box)<{ disabled?: boolean }>(({ theme, disabled }) => {
  return css({
    marginLeft: `calc(${theme.space.xl} + ${theme.space.s})`,
    marginTop: theme.space.s,
    marginBottom: theme.space.l,
    ...(disabled && {
      opacity: 0.6,
      cursor: 'not-allowed',
    }),
  });
});

export const ChangeDeliveryMethodModal = () => {
  const theme = useTheme();
  const state = useRecoilValue(changeDeliveryMethodState);
  const resetState = useResetRecoilState(changeDeliveryMethodState);
  const resetRequestModalState = useResetRecoilState(requestUberDeliveryModalState);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { appointment, loading: appointmentLoading } = useFetchAppointment();
  const { enable: enableUberOrgSetting, loading: settingLoading } = useUberOrganizationSetting();
  const loading = appointmentLoading || settingLoading;

  const { changeDeliveryMethod, loading: updating, error: apiError } = useChangeDeliveryMethod();

  const patient = appointment?.patient ?? null;
  const availableSameDayDelivery =
    (appointment?.quote.availableSameDayDelivery && enableUberOrgSetting) || false;
  const quoteFee = appointment?.quote.fee || 0;

  const initialValues: Fields = {
    deliveryMethod:
      appointment?.deliveryMethod === AppointmentDeliveryMethod.Hand
        ? 'hand'
        : appointment?.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery &&
            availableSameDayDelivery
          ? 'same_day_delivery'
          : 'parcel',
  };

  const displayUberFreePromotion = useFeatureFlag('Issue9813-promotion');

  const handleSubmit = useCallback(
    async (values: Fields) => {
      try {
        if (appointment) {
          await changeDeliveryMethod({
            variables: {
              input: {
                appointmentId: appointment.id,
                deliveryMethod: values.deliveryMethod,
              },
            },
          });
        }
      } catch (error) {
        // 何もしない
      }
    },
    [appointment, changeDeliveryMethod],
  );

  const handleClose = useCallback(() => {
    resetState();
    resetRequestModalState();
  }, [resetRequestModalState, resetState]);

  useEffect(() => {
    if (!state.isOpen) {
      resetState();
      setErrorMessage(undefined);
    }
  }, [resetState, state.isOpen]);

  useEffect(() => {
    setErrorMessage(apiError?.message);
  }, [apiError]);

  if (!appointment || !patient) return null;

  return (
    <Modal open={state.isOpen} size="m" onClose={handleClose}>
      <Modal.Header>受け渡し方法の変更</Modal.Header>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            <Modal.Body>
              {loading ? (
                <Loader open inside />
              ) : (
                <Main>
                  <PatientNameWithAddress patient={patient} />
                  <Box marginTop={theme.space.l}>
                    <EntryList>
                      <EntryList.Head>受け渡し方法</EntryList.Head>
                      <EntryList.Body>
                        <SameDayDeliveryAlert
                          appointmentId={appointment.id}
                          availableSameDayDelivery={availableSameDayDelivery}
                          deliveryMethod={appointment.deliveryMethod}
                          isPending={
                            appointment.uberDelivery?.status === UberDeliveryStatus.Pending
                          }
                          errorMessage={errorMessage}
                          loading={loading}
                          enableUberOrgSetting={enableUberOrgSetting}
                        />
                        <RadioGroup orientation="vertical">
                          <CustomRadio
                            name="deliveryMethod"
                            label="当日配達（Uber Eats）"
                            value="same_day_delivery"
                            checked={formik.values.deliveryMethod === 'same_day_delivery'}
                            onChange={formik.handleChange}
                            disabled={!availableSameDayDelivery}
                          />
                          <RadioSubText disabled={!availableSameDayDelivery}>
                            <Text>
                              Uber Eatsの配達員が、お薬を患者住所へ服薬指導当日中にお届けします
                            </Text>
                            {appointment.deliveryMethod !==
                              AppointmentDeliveryMethod.SameDayDelivery &&
                              availableSameDayDelivery && (
                                <Box marginTop={theme.space.s}>
                                  <Flex alignItems="center">
                                    <Icon icon="alert" size="m" color="pink" />
                                    <Text size="s" color="pink" fontWeight="bold">
                                      当日配達
                                      <TextSpan size="xs" text="（Uber&nbsp;Eats）" />
                                      に変更する場合は、必ず下記を患者に確認・合意の上で行ってください
                                    </Text>
                                  </Flex>
                                  <Text
                                    size="s"
                                    paddingLeft={`calc(${theme.space.l} + ${theme.space.xs})`}
                                  >
                                    誤配達を防ぐため対面での受け取りかつ署名が必要です。また、薬局から患者宅までの距離から算出した配達料（目安
                                    {quoteFee}〜{quoteFee + 100}円）が発生します
                                  </Text>
                                  {displayUberFreePromotion && (
                                    <UberFreeChargePromotionText marginTop={theme.space.xs} />
                                  )}
                                </Box>
                              )}
                          </RadioSubText>
                          <CustomRadio
                            name="deliveryMethod"
                            label="通常配達"
                            value="parcel"
                            checked={formik.values.deliveryMethod === 'parcel'}
                            onChange={formik.handleChange}
                          />
                          <RadioSubText>
                            <Text>薬局の運用に合わせた配送方法にてお薬の配送をお願いします</Text>
                          </RadioSubText>
                          <CustomRadio
                            name="deliveryMethod"
                            label="薬局にてお渡し"
                            value="hand"
                            checked={formik.values.deliveryMethod === 'hand'}
                            onChange={formik.handleChange}
                          />
                          <RadioSubText>
                            <Text>薬局にて直接患者にお薬をお渡しください</Text>
                          </RadioSubText>
                        </RadioGroup>
                      </EntryList.Body>
                    </EntryList>
                  </Box>
                </Main>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button use="base" loading={loading} onClick={formik.submitForm} disabled={updating}>
                保存
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
