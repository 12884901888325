import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MarginProps } from 'styled-system';

import { Flex, Icon, Text } from '~/components/blocks';

const BoldSpan = styled('span')(() =>
  css({
    fontWeight: 'bold',
  }),
);

const Link = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

export const UberFreeChargePromotionText = React.memo((props: MarginProps) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" marginTop={props.marginTop ?? theme.space.m}>
      <Icon color="blue" icon="info" size="m" marginRight={theme.space.s} />
      <Text size="s">
        キャンペーン中につき、期間中に薬の受取完了した分の当日配達利用料は<BoldSpan>無料</BoldSpan>
        となります（
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://intercom.help/pharms/ja/articles/9765203"
        >
          キャンペーン詳細
        </Link>
        <Icon
          icon="blank"
          size="m"
          marginLeft={theme.space.s}
          marginRight={theme.space.s}
          color="linkBlue"
        />
        ）
      </Text>
    </Flex>
  );
});

UberFreeChargePromotionText.displayName = 'UberFreeChargePromotionText';
