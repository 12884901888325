import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import { Box, Flex, Name, Text } from '~/components/blocks';
import { Patient } from '~/graphql';
import { Label } from '~/utils/label';

type Props = {
  patient: Patient;
};

const AddressBox = styled(Box)(({ theme }) =>
  css({
    background: theme.colors.background.bg,
    marginTop: theme.space.m,
    padding: theme.space.m,
  }),
);

export const PatientNameWithAddress = React.memo((props: Props) => {
  const { patient } = props;
  const theme = useTheme();

  const address = patient.pfPatient?.addresses?.[0];

  const addressName = useMemo(() => {
    if (!address) {
      return '未登録';
    }
    return `〒${address.postalCode} ${address.prefecture}${address.city}${address.line}`;
  }, [address]);

  return (
    <>
      <Flex position="relative" alignItems="flex-end">
        <Name
          familyName={patient.familyName}
          givenName={patient.givenName}
          phoneticFamilyName={patient.phoneticFamilyName}
          phoneticGivenName={patient.phoneticGivenName}
          size="xl"
        />
        <Text size="m" marginLeft={theme.space.m} lineHeight={theme.lineHeights.s}>
          {Label.age(patient.birthDate)}({Label.sex(patient.sex)})
        </Text>
      </Flex>
      <AddressBox>
        <Text size="s">{addressName}</Text>
      </AddressBox>
    </>
  );
});

PatientNameWithAddress.displayName = 'PatientNameWithAddress';
