import { object, ObjectSchema, string } from 'yup';

import { Fields } from './types';

export const validationSchema: ObjectSchema<Fields> = object({
  deliveryMethod: string()
    .label('受け渡し方法')
    .oneOf(['parcel', 'hand', 'same_day_delivery'])
    .required(),
});
